import { Fragment, Suspense, lazy } from 'react';
import './App1.css';

const NavTop = lazy(() => import('./components/navtop/NavTop'));
const HomePage = lazy(() => import('./views/HomePage'));
const Footer = lazy(() => import('./components/footer/Footer'));

Promise.all([
  NavTop,
  HomePage,
  Footer
]);


const loadingScreen1 = <div className="d-flex justify-content-center align-items-center vh-100"><div className="loader-greetings"></div></div>;/* HTML: <div class="loader"></div> */

const App = () => {
  return(
    <Fragment>
    <h1 className='semanticHeader' style={{display:'none'}}>Nclean mobilne usługi sprzątające. Pranie tapicerki meblowej, czyszczenie samochodów, pranie dywanów, polerowanie reflektorów, sprzątanie mieszkań i biur, ozonowanie. Dojezdzamy do klienta na terenie Wrocławia i nie tylko!</h1>
      <div className='App'>
        <Suspense fallback={loadingScreen1}>
          <NavTop />
          <HomePage/>
          <Footer/>
        </Suspense>
      </div>
    </Fragment>
  )
}

export default App;
